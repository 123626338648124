
import { useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { selectAuth, login, logout } from '../authNew/authSlice';

export function CheckAuth() {

    const user = useSelector(selectAuth);
    const navigate = useNavigate();

    useEffect(() => { if (!user.isLogged) navigate('/login') });

    return;
}
