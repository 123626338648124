import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { MenuListApp } from '../../shared/AppDrawer';
import { BaseCard, BaseVerticalContainer } from '../../../common/viewBaseComponents';

export function InsertPanel({ writeFunc, setShowInsert }) {
    const [todoText, setTodoText] = useState("");
    const toast = useRef(null);

    const showToast = () => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: 'Inserimento effettuato' });
    };

    const registerClick = () => {
        if (!todoText)
            return;

        writeFunc({ text: todoText, rating: 0, done: false });
        setTodoText("");
        showToast();
    };

    return <BaseVerticalContainer className='flipright'>
        <h3>
            Crea un nuovo elemento
        </h3>
        <BaseCard>
            <InputText
                placeholder="Testo..."
                className='w-full border-round my-2'
                value={todoText}
                onChange={(e) => {
                    setTodoText(e.target.value);
                }} />
        </BaseCard>
        <div>
            <Button onClick={registerClick} label="Aggiungi">
            </Button>
            &nbsp;
            <Button text onClick={() => setShowInsert(false)} label="Chiudi">
            </Button>
            <Toast ref={toast} />
        </div>
    </BaseVerticalContainer>
        ;
}
