import React, { useState } from 'react';
import { Stack, Typography, CardContent, Box } from '@mui/material';
import { UTCToLocalString } from '../common/generic';
import { updateItem, deleteItem, getRealTimeDatabase } from '../common/realtimeDb';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { Checkbox } from 'primereact/checkbox';

import { Rating } from 'primereact/rating';
import { BaseCard, BaseVerticalContainer } from '../../../common/viewBaseComponents';


export function ElementList({ database, data, dbPath, setListKey, isAdminMode }) {


    return <BaseVerticalContainer>
        {data
            .sort((x, y) => (x.done ?? 0) - (y.done ?? 0))
            .sort((x, y) => (y.rating ?? 0) - (x.rating ?? 0))
            //.sort((x, y) => (x.dateChanged < y.dateChanged) ? 1 : 0)
            .map(i => RenderItem(i, database, dbPath, setListKey, isAdminMode))}
    </BaseVerticalContainer>;



    function RenderItem(i, database, dbPath, setListKey, isAdminMode) {

        return <BaseCard key={i.key} className={`flipright flex-column sm:flex-row ${i.done ? 'opacity-60' : 'surface-card'}`}>
            <div className='flex align-self-start'>
                <div className='p-2'>
                    <Checkbox checked={i.done} onChange={e => updateDone(e.checked)} />
                </div>
                <div className='p-2'>
                    {i.text}
                </div>
            </div>

            {isAdminMode && <div className='flex align-items-center flex-column md:flex-row surface-ground sm:surface-card border-round p-2 align-self-end'>

                {i.done && <Button icon="pi pi-times" severity="danger" onClick={callDelete} rounded className='m-3' />}

                {!i.done && <>
                    <Rating
                        value={i.rating}
                        onChange={e => updateRating(e.value)} />
                    <br />
                </>}

                <span className='text-xs m-3 text-400'>
                    {UTCToLocalString(i.dateChanged)}
                </span>

                <Button icon="pi pi-caret-down" onClick={() => setListKey(i.key)} rounded >
                </Button>

            </div>}
        </BaseCard>;

        function callDelete() {
            deleteItem(database, dbPath, i.key);
        }

        function updateRating(e) {
            i.rating = e ?? 0;
            console.log(i)
            updateItem(database, dbPath, i);
        }

        function updateDone(e) {
            i.done = !i.done;
            if (i.done)
                i.rating = 0;

            updateItem(database, dbPath, i);

        }
    }

}