import React, { useState } from 'react';
import { Stack, Typography, CardContent, Box } from '@mui/material';
import { UTCToLocalString } from '../common/generic';
import { updateItem, deleteItem, getRealTimeDatabase } from '../common/realtimeDb';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { selectIsCompact } from '../../config/configSlice';
import { useSelector } from 'react-redux';

import { Rating } from 'primereact/rating';
import { BaseCard, BaseBareCard, BaseBareContainer, BaseVerticalContainer, BaseHorizontalContainer } from '../../../common/viewBaseComponents';


export function ElementFavoritesList({ database, data, dbPath, setListKey, isAdminMode }) {
    
    const compact = useSelector(selectIsCompact);
    const folderURL = "https://giuggioli.dev/folder.png";

    if (isAdminMode)
        return <BaseBareContainer className="flex flex-wrap">
            <div className='text-color-secondary text-center w-full'>
                Sei in modalità admin: puoi spuntare le voci per nasconderle e poi eventualmente eliminarle.
                <br/>
                Le voci sono ordinate in base al voto.
            </div>
            {data
                .sort((x, y) => (x.done ?? 0) - (y.done ?? 0))
                .sort((x, y) => (y.rating ?? 0) - (x.rating ?? 0))
                .sort((x, y) => (y.link === 'folder') - (x.link === 'folder'))
                //.sort((x, y) => (x.dateChanged < y.dateChanged) ? 1 : 0)
                .map(i => RenderItemAdmin(i, database, dbPath, setListKey, isAdminMode))}
        </BaseBareContainer>;

    return <BaseBareContainer className="flex flex-wrap">
        {data
            .filter(x => x.done === false)
            //.sort((x, y) => (x.done ?? 0) - (y.done ?? 0))
            .sort((x, y) => (y.rating ?? 0) - (x.rating ?? 0))
            .sort((x, y) => (y.link === 'folder') - (x.link === 'folder'))
            //.sort((x, y) => (x.dateChanged < y.dateChanged) ? 1 : 0)
            .map(i => RenderItem(i, database, dbPath, setListKey, isAdminMode))}
    </BaseBareContainer>;

    function RenderItem(i, database, dbPath, setListKey, isAdminMode) {

        return (i.link === 'folder') ?
            <BaseBareCard key={i.key} className={`flipright m-1 flex-grow-1 flex-shrink-0 ${compact ? 'w-2rem md:w-3 lg:w-2 h-5rem' : 'w-4rem md:w-3 lg:w-2 h-7rem'}`} >
                <div onClick={() => setListKey(i.key)} className='cursor-pointer flex flex-column md:flex-row align-content-center align-items-center justify-content-center'>
                    <img src={folderURL} className={` ${compact ? 'img-xxs' : 'w-3rem'} h-auto`} alt={i.text} />
                    <div className={`${compact ? 'p-1 text-xxs' : 'p-2 text-xs'}  text-primary`}>
                        {i.text}
                    </div>
                </div>
            </BaseBareCard> :
            <BaseBareCard key={i.key} className={`flipright m-1 flex-grow-1 flex-shrink-0 ${compact ? 'w-2rem md:w-3 lg:w-2 h-5rem' : 'w-4rem md:w-3 lg:w-2 h-7rem'}`} >
                <a href={i.link} target='_blank' rel="noreferrer" style={{ "text-decoration": "none" }}>
                    <div className='flex flex-column md:flex-row align-content-center align-items-center justify-content-center'>
                        <img src={i.imageURL} className={` ${compact ? 'img-xxs' : 'w-3rem'} h-auto`} alt={i.text} />
                        <div className={`${compact ? 'p-1 text-xxs' : 'p-2 text-xs'}  text-primary`}>
                            {i.text}
                        </div>
                    </div>
                </a>
            </BaseBareCard>
            ;
    }

    function RenderItemAdmin(i, database, dbPath, setListKey, isAdminMode) {

        return <BaseBareCard key={i.key} className={`flipright flex flex-wrap flex-column md:flex-row justify-content-between align-items-center w-full`}>
            <div className='flex flex-wrap align-items-center m-2'>

                <div className='p-2'>
                    <Checkbox checked={i.done} onChange={e => updateDone(e.checked)} />
                </div>
                <img src={(i.link === 'folder') ? folderURL : i.imageURL} className='w-3rem h-auto ml-3' alt={i.text} />
                <div className='p-2'>
                <InputText
                placeholder="Text..."
                className='border-round my-2'
                value={i.text}
                onChange={(e) => {
                    updateText(e.target.value);
                }} />
                </div>
            </div>

            <div className='flex flex-wrap align-items-center flex-column md:flex-row surface-ground md:surface-card border-round p-2 m-1 align-self-end w-full md:w-auto'>

                {i.done && <Button icon="pi pi-times" severity="danger" onClick={callDelete} rounded className='m-1' />}

                {!i.done && <>
                    <Rating
                        value={i.rating}
                        onChange={e => updateRating(e.value)} />
                </>}

                <span className='text-xs m-3 text-400'>
                    {UTCToLocalString(i.dateChanged)}
                </span>

                {(i.link === 'folder') && <Button icon="pi pi-caret-down" onClick={() => setListKey(i.key)} rounded >
                </Button>}

            </div>
        </BaseBareCard>;

        function callDelete() {
            deleteItem(database, dbPath, i.key);
        }

        function updateRating(e) {
            i.rating = e ?? 0;
            //console.log(i)
            updateItem(database, dbPath, i);
        }

        function updateText(e) {
            i.text = e ?? "";
            //console.log(i)
            updateItem(database, dbPath, i);
        }

        function updateDone(e) {
            i.done = !i.done;
            if (i.done)
                i.rating = 0;

            updateItem(database, dbPath, i);

        }
    }

}