import { getDatabase, ref, set, push, child } from "firebase/database";
import { onValue, orderByChild, query, update } from "firebase/database";
import { snapshotToArray } from "./generic";

export function getRealTimeDatabase(app) {
    return getDatabase(app, 'https://giuggioli-net-default-rtdb.europe-west1.firebasedatabase.app');
}

export function listenDataChange(db, path, callBack) {
    const starCountRef = child(ref(db), path)

    onValue(starCountRef, (snapshot) => {
        // data = snapshot.val();
        const data = snapshotToArray(snapshot);
        callBack(data)
    });
    //const mostViewedPosts = query(ref(db, getToDoPathByUser(user, listKey)), orderByChild('dateChanged'));
    //console.log(mostViewedPosts)
}

export function updateItem(db, path, item) {
    //const starCountRef = child(ref(db), getToDoPathByUser(user, listKey) + item.key)
    //console.log(getToDoPathByUser(user, listKey) + item.key)

    item.dateChanged = new Date().toISOString()

    const updates = {};
    updates[path + item.key] = item;
    // you can put more paths here and the update will be atomic!
    //updates['/user-posts/' + uid + '/' + newPostKey] = postData;
    update(ref(db), updates);
}

export function deleteItem(db, path, itemKey) {
    const starCountRef = child(ref(db), path + itemKey)
    set(starCountRef, null)
}

export function writeUserData(db, path, item) {
    const postData = {
        ...item,
        dateChanged: new Date().toISOString()
    };

    // sempre un nuovo elemento con PUSH
    // const newPostKey = push(child(ref(db), '/messaging/users/' + user.uid), postData).key;
    push(child(ref(db), path), postData);

    //upsert con SET
    // set(child(ref(db), '/messaging/users/' + user.uid + '/todos/'), postData);
}
