import React from "react";

export function BaseCard({ children, className }) {
    return <div data-label="BaseCard"
        className={[
            'flex flex-row justify-content-between align-items-center surface-card border-round p-4 my-2 w-full hover:surface-hover',
            className].join(' ')
        }
    >{children}</div>;
}

export function BaseBareCard({ children, className }) {
    return <div data-label="BaseCard"
        className={[
            'surface-card border-round p-4 my-2 hover:surface-hover',
            className].join(' ')
        }
    >{children}</div>;
}

export function BaseHorizontalContainer({ children, className }) {
    return <div data-label="BaseHorizontalContainer"
        className={[
            'flex flex-row justify-content-between align-items-stretch flex-wrap w-11 md:w-9',
            className].join(' ')
        }
    >{children}</div>
}

export function BaseBareContainer({ children, className }) {
    return <div data-label="BaseBareContainer" 
    className={[
        ' w-11 md:w-9 ',
        className].join(' ')
    }      
    >{children}</div>
}

export function BaseVerticalContainer({ children, className }) {
    return <div data-label="BaseVerticalContainer"
        className={[
            'flex flex-column justify-content-center align-items-center w-11 md:w-9',
            className].join(' ')
        }
    >{children}</div>
}

export function BaseComponentContainer({ children, className }) {
    return <div data-label="BaseComponentContainer"
        className={[
            'flex flex-column justify-content-center align-items-center w-full m-auto',
            className].join(' ')
        }
    >{children}</div>;
}

export function BasePageContainer({ children, className }) {
    return <div data-label="BasePageContainer"
        className={[
            'flex flex-column justify-content-center align-items-center w-full m-auto p-5',
            className].join(' ')
        }
    >{children}</div>;
}

export function BaseLayoutContainer({ children, className }) {
    return <div data-label="BaseLayoutContainer"
        className={[
            'flex flex-column justify-content-between align-items-stretch',
            className].join(' ')
        }
    >{children}</div>
}