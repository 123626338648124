import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { MenuListApp } from '../../shared/AppDrawer';
import { BaseCard, BaseVerticalContainer } from '../../../common/viewBaseComponents';

export function InsertFavoritesPanel({ writeFunc, setShowInsert }) {
    const [todoText, setTodoText] = useState("");
    const [link, setLink] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [toastShown, setToastShown] = useState(false);

    const toast = useRef(null);

    //useEffect( () => showInfoToast(), []);

    const showToast = () => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: 'Inserimento effettuato' });
    };

    const showInfoToast = () => {
        if( ! toastShown)
            toast.current.show({ sticky: true, severity: 'info', summary: 'Lo sapevi?', detail: 'Puoi creare una cartella scrivendo \'folder\' nel link e nell\'URL immagine' });
        setToastShown(true);
    };

    const registerClick = () => {
        if (!todoText || !imageURL || ! link)
            return;

        writeFunc({ text: todoText, link: link, imageURL: imageURL, rating: 0, done: false });
        
        setTodoText("");
        setImageURL("");
        setLink("");

        showToast();
    };

    return <BaseVerticalContainer className='flipright'>
        <h3>
            Crea un nuovo preferito
        </h3>
        <BaseCard>
            <InputText
                placeholder="Testo..."
                className='w-full border-round my-2'
                value={todoText}
                onFocus={showInfoToast}
                onChange={(e) => {
                    setTodoText(e.target.value);
                }} />
        </BaseCard>
        <BaseCard>
            <InputText
                placeholder="Link..."
                className='w-full border-round my-2'
                value={link}
                onChange={(e) => {
                    setLink(e.target.value);
                }} />
        </BaseCard>
        <BaseCard>
            <InputText
                placeholder="URL immagine..."
                className='w-full border-round my-2'
                value={imageURL}
                onChange={(e) => {
                    setImageURL(e.target.value);
                }} />                
        </BaseCard>        
        <div>
            <Button onClick={registerClick} label="Aggiungi">
            </Button>
            &nbsp;
            <Button text onClick={() => setShowInsert(false)} label="Chiudi">
            </Button>
            <Toast ref={toast} position="bottom-left" />
        </div>
    </BaseVerticalContainer>
        ;
}
