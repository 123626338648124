import React from 'react';
import { useNavigate } from "react-router-dom";

export function Home() {
    const navigate = useNavigate();
    const handleClick = (e) => (e.detail == 2) ? navigate("/login") : navigate("/");
    return <div style={{ textAlign: 'center' }}>
        <div onClick={handleClick} style={{ padding: 10, margin: 10, borderRadius: 10, backgroundColor: '#ccc' }}>
            Giuggioli.net <br />
            Sito in costruzione
        </div>
    </div>;
}
