

import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { AppBar, Typography, Stack, Toolbar } from '@mui/material';
import { useState, useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { selectAuth, login, logout } from '../authNew/authSlice';
import AppDrawer from './AppDrawer';
import { CheckAuth } from '../authNew/checkAuth';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { BaseLayoutContainer, BasePageContainer } from "../../common/viewBaseComponents";

export function Layout() {
    const user = useSelector(selectAuth);
    const navigate = useNavigate();
    const location = useLocation();

    const getCurrentTitle = () => {
        switch (location.pathname) {
            case "/firebase":
                return "To-Do";
            case "/config":
                return "Impostazioni"
            default:
                return "";
            case "/favorites":
                return "Preferiti";
        }
    };

    return (
        <BaseLayoutContainer>
            <CheckAuth />
            {user.isLogged && <TopAppBar />}
            <Outlet />
        </BaseLayoutContainer>
    );



    function TopAppBar() {
        return <div className="p-2 flex justify-content-between surface-0">
            <AppDrawer navigate={navigate} /> 
            <span className="font-medium text-800 zoominleft ">{getCurrentTitle()}</span>
            <Avatar image={user.photoURL} size="large" shape="circle" onClick={() => navigate("/config")} />
        </div>;
    }
}
