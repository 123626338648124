import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toggleTheme, toggleCompact, selectTheme } from '../configSlice';
import { useDispatch, useSelector } from 'react-redux';

import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';
import { BaseCard, BasePageContainer, BaseComponentContainer, BaseVerticalContainer } from '../../../common/viewBaseComponents'
import { selectAuth } from '../../authNew/authSlice';
import { Button } from 'primereact/button';
import { selectIsCompact } from '../../config/configSlice';


export function Config() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(useSelector(selectTheme) === '/themes/dark-purple.css');
    const [checked2, setChecked2] = useState(useSelector(selectIsCompact));
    const auth = useSelector(selectAuth);

    return <BasePageContainer className={'w-11 flipleft'}>
        <BaseComponentContainer>
            <h3>
                Configurazione
            </h3>
            <BaseVerticalContainer>
                <BaseCard>
                    <div className='font-light'>Tema chiaro/scuro </div>
                    <div><InputSwitch checked={checked} onChange={() => { dispatch(toggleTheme()); setChecked(!checked) }} /></div>
                </BaseCard>
                <BaseCard>
                    <div className='font-light'>Modalità compatta </div>
                    <div><InputSwitch checked={checked2} onChange={() => { dispatch(toggleCompact()); setChecked2(!checked2) }} /></div>
                </BaseCard>                
                <BaseCard>
                    <div className='font-light'>User </div>
                    <div>{auth.displayName}</div>
                </BaseCard>
                <BaseCard>
                    <div className='font-light'>Email </div>
                    <div>{auth.email}</div>
                </BaseCard>
                <BaseCard>
                    <div className='font-light'>Logout </div>
                    <div>
                        <Button onClick={() => navigate('/logout')} label='Logout'></Button>
                    </div>
                </BaseCard>
                v 0.9.2
            </BaseVerticalContainer>
        </BaseComponentContainer>
    </BasePageContainer>;
}
