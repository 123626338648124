import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/authNew/authSlice';
import configReducer, { selectIsCompact } from '../features/config/configSlice';
import { selectTheme } from '../features/config/configSlice';
import { toLocalStorage } from '../common/localStorage';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    config: configReducer
  },
});

let currentValue, currentValue2
function handleChange() {

  // -------- theme
  let previousValue = currentValue
  currentValue = selectTheme(store.getState())

  if (previousValue !== currentValue) {
    toLocalStorage('theme', currentValue)
  }
  //---------- compact
  let previousValue2 = currentValue2
  currentValue2 = selectIsCompact(store.getState())

  if (previousValue2 !== currentValue2) {
    toLocalStorage('compact', currentValue2)
  }

}

const unsubscribe = store.subscribe(handleChange)
// unsubscribe()