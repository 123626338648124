import React, { useEffect, useState } from 'react';
import { FirebaseDynamicList } from '../components/FirebaseDynamicList';
import { getRealTimeDatabase } from '../common/realtimeDb';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../authNew/authSlice';
import { Card } from 'primereact/card';
import { BasePageContainer } from '../../../common/viewBaseComponents';

export default function FirebasePage({ app }) {

    const auth = useSelector(selectAuth);
    var user = auth;

    // // console.log('FireBasePage:')
    // // console.log(user)

    if (!auth.isLogged)
        return (
            <div>
                Devi essere loggato nel sistema
            </div>
        );


    // Google Auth ------------

    //const [user, setUser] = useState(null);
    //useEffect(() => watchUser(setUser, setUser), []);

    // if (user == null)
    //     return (<Container sx={{ p: 2 }} >
    //         <Box m={3} sx={{ boxShadow: 3, padding: 2 }}>
    //             Devi essere loggato
    //         </Box>
    //     </Container>);

    // Google Auth ------------

    return (<>

        <BasePageContainer>
            <FirebaseDynamicList
                database={getRealTimeDatabase(app)}
                listKey={""}
                dbPath={"/users/" + user.uid + "/list/"}
                showGoBackButton={false}
            />
        </BasePageContainer>

    </>);
}