import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fromLocalStorage } from '../../common/localStorage';

let storedTheme = fromLocalStorage('theme');
let storedCompact = fromLocalStorage('compact');

const themes = {
  lightBlue: '/themes/light-blue.css', // Lara themes
  darkPurple: '/themes/dark-purple.css',
}
const initialState = {
  isMobile: false,
  theme: storedTheme ?? themes.lightBlue,
  isCompact: storedCompact ?? false,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = (state.theme === themes.lightBlue) ? themes.darkPurple : themes.lightBlue;
    },
    toggleCompact: (state) => {
      state.isCompact = !state.isCompact;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { toggleTheme, setIsMobile, toggleCompact } = configSlice.actions;
export const selectTheme = (state) => state.config.theme;
export const selectIsMobile = (state) => state.config.isMobile;
export const selectIsCompact = (state) => state.config.isCompact;

export default configSlice.reducer;
