import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const initialState = {
    displayName: '',
    isLogged: false,
    uid: '',
    email: '',
    photoURL: ''
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        login: (state, action) => {
            state.displayName = action.payload.displayName;
            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.photoURL = action.payload.photoURL;
            state.isLogged = true;
        },
        logout: (state) => {
            state.displayName = "";
            state.uid = "";
            state.email = "";
            state.photoURL = "";
            state.isLogged = false;
        },
    },
});

export const { login, logout } = authSlice.actions;

// selector con tutto (non raccomandato, meglio individualiperchè più performante)
export const selectAuth = (state) => state.auth;

export default authSlice.reducer;