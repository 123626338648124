
import { FirebaseAuthGoogle, watchUser } from '../../firebase/common/firebaseAuthGoogle';
import { getAuth } from "firebase/auth";
import { useNavigate, Outlet } from "react-router-dom";
import { Button } from 'primereact/button';
import react, { useState, useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { selectAuth, login, logout } from '../authSlice';

export function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginCallBack = (user) => {
        //console.log(user)
        dispatch(login(
            {
                displayName: user.displayName,
                isLogged: false,
                uid: user.uid,
                email: user.email,
                photoURL: user.photoURL
            }));
        navigate('/favorites')
    }

    useEffect(() => watchUser(loginCallBack, null), []);

    const registerClick = () => FirebaseAuthGoogle();

    return <div className='flex p-5 justify-content-center'>

        <Button onClick={registerClick} label="Login with Google!"></Button>

    </div>;

}
