// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD4VgXWAf7RbB7ewRMCsUxo_bjaCxxkaTo",
    authDomain: "giuggioli-net.firebaseapp.com",
    projectId: "giuggioli-net",
    storageBucket: "giuggioli-net.appspot.com",
    messagingSenderId: "834771737288",
    appId: "1:834771737288:web:e5ac8e51a857c39999e3fd",
    measurementId: "G-FE4DWK6M7V",
    databaseUrl: "https://giuggioli-net-default-rtdb.europe-west1.firebasedatabase.app/"
};

export function FirebaseInit() {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return { app, analytics };
}