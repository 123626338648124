import React, { useEffect, useState } from 'react';
import { listenDataChange, getRealTimeDatabase, writeUserData } from '../common/realtimeDb';
import { InsertPanel } from './InsertPanel';
import { NoDataPanel } from './NoDataPanel';
import { ElementList } from './ElementList';
import { useSelector } from 'react-redux';
import { selectIsMobile } from '../../config/configSlice';
import { Button } from 'primereact/button';

import { SpeedDial } from 'primereact/speeddial';
import { BaseComponentContainer, BaseHorizontalContainer, BasePageContainer } from '../../../common/viewBaseComponents';


export function FirebaseDynamicList({ database, dbPath, listKey, setListKey, showGoBackButton = false }) {
    const [data, setData] = useState(null);
    const [showInsert, setShowInsert] = useState(false);
    const [listKeyInternal, setListKeyInternal] = useState("");
    const isMobile = useSelector(selectIsMobile);
    const [isAdminMode, setIsAdminMode] = useState(false);

    const GoBack = () => {
        return showGoBackButton ?
            (isMobile) ?
                <SpeedDial type="circle" showIcon="pi pi-caret-up" style={{
                    position: 'absolute',
                    bottom: 26,
                    right: 170,
                }} onClick={() => setListKey("")}>
                </SpeedDial>
                :
                <div>
                    <Button icon="pi pi-caret-up" onClick={() => setListKey("")} label="Sali di livello">
                    </Button>
                </div>
            : <></>
    }

    const InsertButton = () => {
        return (isMobile) ?
            <SpeedDial type="circle" onClick={() => setShowInsert(true)} style={{
                position: 'absolute',
                bottom: 26,
                right: 22,
            }}>
            </SpeedDial>
            :
            <Button icon="pi pi-plus" onClick={() => setShowInsert(true)} label='Inserisci'>
            </Button>
    }

    const AdminModeButton = () => {
        return (isMobile) ?
            <SpeedDial showIcon='pi pi-bolt' type="circle" onClick={() => setIsAdminMode(!isAdminMode)} style={{
                position: 'absolute',
                bottom: 26,
                right: 96,
            }}
                buttonClassName={`${isAdminMode ? 'p-button-primary' : 'p-button-outlined'}`}
            >
            </SpeedDial>
            :
            <Button icon="pi pi-bolt" onClick={() => setIsAdminMode(!isAdminMode)} label='Admin' outlined={!isAdminMode}>
            </Button>
    }

    const createElement = (element) => {
        writeUserData(database, dbPath, element, listKey);
    }

    const ButtonGroup = () => {
        return <BaseHorizontalContainer className='my-2'>
            <InsertButton />
            <AdminModeButton />
            <GoBack />
        </BaseHorizontalContainer>;
    }

    useEffect(() => listenDataChange(database, dbPath, setData), []);

    if (listKeyInternal)
        return <FirebaseDynamicList
            database={database}
            dbPath={dbPath + listKeyInternal + '/sub-level/'}
            listKey={listKeyInternal}
            setListKey={setListKeyInternal}
            showGoBackButton={true}
        />

    if (showInsert == true)
        return (<InsertPanel setShowInsert={setShowInsert} writeFunc={createElement} />);

    if (data == null || data.length == 0)
        return <BaseComponentContainer>
            <ButtonGroup />
            <NoDataPanel />
        </BaseComponentContainer>;

    return <BaseComponentContainer>
        <ButtonGroup />
        <ElementList database={database} data={data} dbPath={dbPath} setListKey={setListKeyInternal} isAdminMode={isAdminMode} />
    </BaseComponentContainer>;
}