import React, { useState, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { ListBox } from 'primereact/listbox';
import { useNavigate, Outlet } from "react-router-dom";
import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../authNew/authSlice';

export default function AppDrawer() {
    const [visible, setVisible] = useState(false);

    return (
        <div className="flex-none">
            <Sidebar visible={visible} onHide={() => setVisible(false)}>
                <MenuListApp />
            </Sidebar>
            <Button icon="pi pi-bars" onClick={() => setVisible(true)} text size="lg" />
        </div>
    )
}

export function MenuListApp() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const auth = useSelector(selectAuth);

    const items = [
        {
            label: 'Funzioni',
            items: [
                {
                    label: 'Preferiti',
                    icon: 'pi pi-star',
                    command: () => { navigate('favorites') }
                },
                {
                    label: 'To-Do',
                    icon: 'pi pi-list',
                    command: () => { navigate('firebase') }
                },                
                // {
                //     label: 'Toast',
                //     icon: 'pi pi-times',
                //     command: () => {
                //         toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Testo di prova', life: 3000 });
                //     }
                // }
            ]
        },
        {
            label: 'Configurazione',
            items: [
                {
                    label: 'Giuggioli.net',
                    icon: 'pi pi-external-link',
                    url: 'https://giuggioli.net/'
                },
                {
                    label: 'Impostazioni',
                    icon: 'pi pi-cog',
                    command: () => { navigate('config') },
                }
            ]
        },
        { separator: true },
        {
            command: () => { toast.current.show({ severity: 'info', summary: 'Info', detail: 'Testo di prova', life: 3000 }); },
            template: (item, options) => {
                return (
                    <button onClick={(e) => options.onClick(e)} className={classNames(options.className, 'w-full p-link flex align-items-center')}>
                        <Avatar image={auth.photoURL} className="mr-2" shape="circle" />
                        <div className="flex flex-column align">
                            <span className="font-bold">{auth.displayName}</span>
                            <span className="text-sm">{auth.email}</span>
                        </div>
                    </button>
                )
            }
        }
    ];

    return (
        <div>
            <Toast ref={toast} />
            <Menu model={items} className="border-0 " style={{ width: '100%' }} />
        </div>)
}
