
import { FirebaseAuthGoogle, watchUser } from '../../firebase/common/firebaseAuthGoogle';
import { getAuth } from "firebase/auth";
import { useNavigate, Outlet } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { selectAuth, login, logout } from '../authSlice';

export function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        logoutClick();
        navigate('/')
    });

    const logoutClick = () => {
        getAuth().signOut();
        dispatch(logout());
    };

    return <div>Log-Out</div>
}
