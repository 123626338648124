import React, { useEffect } from 'react';
import { FirebaseInit } from './features/firebase/common/firebaseInit';
import FirebasePage from './features/firebase/pages/FirebasePage';
import FirebaseFavoritesPage from './features/firebase/pages/FirebaseFavoritesPage';
import './App.css';
import {
  BrowserRouter,
  Routes, Route
} from "react-router-dom";
import { selectTheme } from './features/config/configSlice';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { setIsMobile } from './features/config/configSlice';
import { Layout } from './features/shared/Layout';
import { Config } from './features/config/pages/Config';
import { Home } from './features/shared/Home';
import { Login } from './features/authNew/pages/Login';
import { Logout } from './features/authNew/pages/Logout';

//theme: now it's in Index.html: <link id="app-theme" rel="stylesheet" type="text/css" href="saga-blue.css">
// import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

// CSS
import '/node_modules/primeflex/primeflex.css'


function App() {
  let { app, analitycs } = FirebaseInit();
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  // mobile resizing ------
  const isMobileFunc = () => window.innerWidth < 720;
  const handleResize = () => {
    if (isMobileFunc()) {
      dispatch(setIsMobile(true))
    } else {
      dispatch(setIsMobile(false))
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  })
  // mobile resizing ------

  // change theme -----
  const changeTheme = (theme) => {
    let themeLink = document.getElementById('app-theme');
    if (themeLink) {
      themeLink.href = theme;
    }
  }

  useEffect(() => changeTheme(theme), [theme])



  return (
    <div className="App">
      <RouterStructure app={app} />
    </div>
  );
}


function RouterStructure({ app }) {
  return <BrowserRouter>
    <Routes>
      <Route path="/" >
        <Route index element={<Home />} />
        <Route path='login' element={<Login />} />
        <Route path='logout' element={<Logout />} />
      </Route>
      <Route element={<Layout />}>
        {/* <Route index path="counter" element={<Counter />} />
      <Route path="login" element={<AuthUserLogin />} />
      <Route path="login2" element={<AuthComponent />} /> */}
        <Route path="firebase" element={<FirebasePage app={app} />} />
        <Route path="favorites" element={<FirebaseFavoritesPage app={app} />} />
        <Route path="config" element={<Config />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  </BrowserRouter>;
}

function NoMatch() {
  return <div>404!</div>
}

export default App;
